@import './_variables';
@import './_mixins';

* {
    box-sizing: border-box;
    outline: none;
}

html,
body {
    height: 100%;
    overflow-x: hidden;
}

body {
    position: relative;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $main-text-color;
    background-color: $main-background-color;
    font-size: $main-text-size;
    background-image: url('../resources/assets/landscape.jpg');
    background-size: cover;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}
