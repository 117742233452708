@import '../../styles/variables';

.DashboardTable {
    width: 100%;
    height: 100%;
    position: relative;
}

.noEntries {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    .MagicBookLottie {
        height: 20rem;
    }

    :nth-child(2) {
        margin-top: -2rem;
        color: $brown;
    }
}

.tableContainer {
    height: inherit;
    scrollbar-width: none;
}

.tableContainer::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.tableHeaderCell,
.tableHeaderProblemCell {
    border-bottom: 1px solid $brown !important;
}

.tableHeaderProblemCell {
    text-align: center !important;
}

.tableCell,
.badgesCell {
    border-bottom: 1px solid $light-brown !important;
}

.badgesCell {
    padding: unset !important;
}

.badge_container {
    display: flex;
    justify-content: space-evenly;
}

.badge {
    position: relative;
    height: 48px;
    width: 48px;

    :first-child {
        position: absolute;
        top: 0;
        left: 0;
    }

    :nth-child(2) {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
    }

    .confetti {
        width: 10rem;
        height: 10rem;
        position: absolute;
        top: -3.5rem;
        left: -3.5rem;
        z-index: 2;
    }
}
