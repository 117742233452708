.BriefingText {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;
    font-size: 120%;

    code {
        background: rgba(255, 255, 255, 0.2);
        padding: 15px;
        border-radius: 10px;
        white-space: pre-line;
        display: block;
    }

    blockquote {
        display: block;
        background-color: #80008020;
        padding: 0.1px 20px;
        border-radius: 10px;
        margin-right: 50px;
        &::before {
            content: '🪄';
            position: absolute;
            transform: translateX(-60px);
            font-size: 2rem;
        }
    }
}
