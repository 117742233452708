@import '../../styles/_variables';
.HomePage {
    position: relative;
    width: 100vw;
    height: 100vh;

    padding: 2em;

    .container {
        position: relative;
        border-radius: 20px;

        display: flex;
        height: 100%;
        padding: 1em;

        .background::before {
            background-image: url('../../resources/assets/leather.png');
            opacity: 0.25;
            content: '';
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
        }

        .background {
            border-radius: 20px;
            background-color: $dark-brown;

            mix-blend-mode: darken;

            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;

            .sigil {
                margin: 1em;
                width: 20%;
                mix-blend-mode: color-dodge;

                z-index: 0;
            }
        }

        .content {
            position: relative;
            display: inline-flex;
            width: 100%;
            height: 100%;

            z-index: 1;
            > .navigation {
                position: relative;
                width: 20%;

                > * {
                    font-family: 'aladin';
                    color: $light-brown;
                    text-align: center;

                    font-size: 1.5vw;
                }

                > .button {
                    display: block;
                    margin: 0 auto;
                }
            }

            .body {
                width: 78%;
                margin-left: auto;

                padding-left: 10%;
                padding-right: 6%;
                padding-top: 4%;
                padding-bottom: 4%;

                background-image: url('../../resources/assets/paper.svg#svgView(preserveAspectRatio(none))');
                background-size: 100% 100%;

                display: flex;
                align-items: center;
                flex-direction: column;

                .loadingContainer {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    align-items: center;
                    height: 100%;
                    > h2 {
                        margin-top: -2rem;
                        color: $brown;
                    }
                }
            }
        }
    }
}
